<template>
    <v-card>
        <MyCardTitle modulo="Paciente" :cardInfo="paciente" />

        <v-row dense>
            <v-col cols="10">
                
                <v-chip 
                    v-for="(e, v) in paciente.equipe_multi_disciplinar" :key="v">      
                {{ e.nome || e }}
                </v-chip>
            </v-col>
            <v-col>
                <v-btn
                    icon
                    @click="gravar"
                >
                    <v-icon size="40">mdi-content-save</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="12">
                <v-data-table 
                    :loading="loading_equipe_multidisciplinar" 
                    :search="search"
                    :items="equipe_multidisciplinar" 
                    :headers="header_em"
                >
                    <template v-slot:top>
                        <v-row dense>
                            <v-col cols="10">
                                <v-text-field v-model="search" label="Pesquisar" class="mx-0"></v-text-field>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:[`item.edit`]="{ item }">
                        <v-checkbox 
                            v-model="check_emd" 
                            :value="item" 
                            @change="atualiza_equipe_multidisciplinar()"
                            return-object
                        ></v-checkbox>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
import api from '@/http'
export default {
    name: "EquipeMultidiciplinar",
    props: {
        paciente:{
            type:Object
        }
    },
    components: {
        MyCardTitle: () => import('../../../uteis/card_title.vue'),
    },
    data: () => ({
        search: '',
        loading_equipe_multidisciplinar: false,
        equipe_multidisciplinar: [],
        check_emd: [],
        header_em: [
            { text: "Id", value: "id" },
            { text: "Nome Completo", value: "nome" },
            { text: "Especialidade", value: "especialidade.nome" },
            { text: "Ativo", value: "edit" },
        ]
    }),
    methods: {
        get_equipe_multidisciplinar() {
            this.loading_plano = true
            api(`contas/equipe-multidisciplinar/`)
                .then((e) => {
                    this.equipe_multidisciplinar = e.data
                    
                    if(this.paciente.equipe_multi_disciplinar.length>0){
                        this.check_emd = this.paciente.equipe_multi_disciplinar
                    }
                })
                .finally(() => { this.loading_plano = false; });
        },
        atualiza_equipe_multidisciplinar(){
            this.paciente.equipe_multi_disciplinar = this.check_emd
        },
        gravar() {
            this.loading_equipe_multidisciplinar = true;
            let ce = this.check_emd
            let tmp =  this.paciente
            tmp.equipe_multi_disciplinar = Object.keys(ce).map((e)=>ce[e].id)
            api.put(`pacientes/paciente/${tmp.id}/`,tmp)
            .then((e) => {
                this.paciente = e.data
                this.loading_equipe_multidisciplinar = false;
            })
            .finally(()=>this.loading_equipe_multidisciplinar=false)
        }
    },
    mounted() {
        this.get_equipe_multidisciplinar()

    },
}
</script>